import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div style={{ marginTop: '10rem', textAlign: 'center' }}>
      <h1>404 | Not found</h1>
      <p>
        Diese Seite existiert leider nicht... Hier geht's{' '}
        <Link to="/">zurück</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
